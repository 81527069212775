'use strict'

###*
 # @ngdoc object
 # @name mundoAuthentication.controller:UserAccountCtrl

 # @description

###
class UserAccountCtrl
  ### @ngInject ###
  constructor: (
    $rootScope
    MyUser
  ) ->
    @ctrlName = 'UserAccountCtrl'
    @user = $rootScope.user

    @timezones = []
    @locales =
      en: 'English'
      nl: 'Nederlands'
      fr: 'Français'

    @profileErrorMessage = null
    @profileEntity =
      username: null
      email: null
      timezone: null
      locale: null

    @passwordErrorMessage = null
    @passwordEntity =
      oldPassword: null
      plainPassword: null
      plainPasswordVerification: null

    @reloadInfo = (trigger = false) ->
      if trigger
        $rootScope.$broadcast 'mundo:user:reload'
        return

      if not @user
        return

      @profileEntity.username = @user.username
      @profileEntity.email = @user.email
      @profileEntity.timezone = @user.timezone
      @profileEntity.locale = @user.locale

      @passwordEntity.oldPassword = null
      @passwordEntity.plainPassword = null
      @passwordEntity.plainPasswordVerification = null

    @reloadInfo()

    $rootScope
      .$on 'mundo:authentication:success', (event, data) =>
        @user = $rootScope.user
        @reloadInfo()

    @updateProfile = () =>
      @profileErrorMessage = null

      MyUser.one()
        .patch @profileEntity
        .then (result) =>
          @profileErrorMessage = null
          # @reloadInfo(true)
          location.reload()
        , () =>
          @profileErrorMessage = 'app.errors.could-not-update-profile'

    @updatePassword = () =>
      @passwordErrorMessage = null

      MyUser.one()
        .patch
          plainPassword: @passwordEntity.plainPassword
        .then (result) =>
          @passwordErrorMessage = null
          @reloadInfo(true)
        , () =>
          @passwordErrorMessage = 'app.errors.could-not-set-password'

angular
  .module('mundoAuthentication')
  .controller 'UserAccountCtrl', UserAccountCtrl
